<script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
import PageHeader from '@components/page-header'
import axios from '@/axios'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { BASE_IMAGE_URL } from '@src/config/configs'

/**
 * Contents component
 */
export default {
  page: {
    title: 'Contents',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    Layout,
    vueDropzone: vue2Dropzone,
    PageHeader,
  },
  data() {
    return {
      baseImageUrl: BASE_IMAGE_URL,

      title: 'Cover',
      data: {},
      image: {},
      ismanuallyadd: false,
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 375,
        maxFilesize: 10,
        maxFiles: 1,
        addRemoveLinks: true,
        dictDefaultMessage: "<i class='fa fa-cloud-upload'></i> UPLOAD ME",
      },
      items: [
        {
          text: 'Dashbord',
          href: '/',
        },
        {
          text: 'Cover',
          active: true,
        },
      ],
    }
  },
  mounted() {
    this.getCategory()
  },
  methods: {
    vfileAdded(file) {
      this.image = file
      this.ismanuallyadd = false
    },
    vfilemanuallyAdded(file) {
      this.ismanuallyadd = true
    },
    async getCategory() {
      const data = await axios
        .get(`/api/category/${this.$route.params.id}`)
        .then((res) => {
          this.data = res.data
          var file = { size: 123, name: 'Icon', type: 'image/png' }
          if (this.data.category_image) {
            var url = `${this.baseImageUrl}/${this.data.category_image}`
            this.$refs.myVueDropzone.manuallyAddFile(file, url)
          }
        })
        .catch((err) => {
          this.$toast.error(err.response.data.error.message)
        })
    },
    disabledDropzone() {
      this.$refs.myVueDropzone.disable()
    },
    async handleClickCreate() {
      const data = new FormData()

      let img = this.image
      data.append('image', img)

      await axios
        .patch(`/api/category/${this.$route.params.id}`, data)
        .then((result) => {
          this.$toast.success('Saved successfully')
          this.$router.back()
          // location.reload()
        })
        .catch((err) => {
          this.$toast.error(err.response.data.error.message)
        })
    },
  },
}
</script>

<template>
  <Layout>
    <PageHeader @click="this.$router.back()" :title="title" :items="items" />

    <div class="row page-title align-items-center">
      <div class="col-md-3 col-xl-6">
        <h4 class="mb-1 mt-0"></h4>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h5>
              Cover Category Course
              <small
                class="color-text-small"
              >Width 375 x Height 170. Just only one.</small>
            </h5>
            <b-form-group label-cols-lg="0">
              <vue-dropzone
                @vdropzone-file-added="vfileAdded"
                @vdropzone-file-added-manually="vfilemanuallyAdded"
                id="image"
                ref="myVueDropzone"
                :options="dropzoneOptions"
              ></vue-dropzone>
            </b-form-group>
            <button
              @click="handleClickCreate"
              class="btn btn-primary width-lg rounded-pill aligncenter m-auto"
            >
              <feather type="image"></feather>&nbsp;Create
            </button>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
